<router>
{
    name: 'Communications',
}
</router>
<template lang="pug">
v-container(fluid)
    v-tabs(v-model='currentTab', color='brand', show-arrows, light)
        v-tab Email
        v-tab Bulletins
    v-tabs-items(v-model='currentTab', light)
        v-tab-item
            v-row
                v-col
                    .text-h4 Emails
            v-row
                v-col
                    v-btn.white--text(rounded, color='brand', @click='goToSendEmail') Send a new email
            v-row
                v-col
                    EmailTemplateManager
        v-tab-item
            v-row
                v-col
                    BulletinManager

</template>
<script>

import EmailTemplateManager from '@/components/devops/emailtemplatemanager';
import BulletinManager from '@/components/devops/bulletinmanager';

export default {
    meta: {
        role: 'devopscomms'
    },
    data () {
        return {
            currentTab: 0
        };
    },
    components: {
        EmailTemplateManager,
        BulletinManager
    },
    methods: {
        goToSendEmail () {
            this.$router.push('/devops/sendEmail');
        }
    }
};
</script>

<style scoped>
</style>
